// import { Proof } from './merkle';
const Web3 = require("web3");
const ABI = require("./SueiBianDispenser.json");
const bigNumber = require("bignumber.js");
const web3 = new Web3(window["ethereum"]);
const contractAddress = "0x81b61ebD52caA5C97545D20Dc79284b15720B3f6";
const prod = true;

export async function ConnectWallet() {
  if (window["ethereum"]) {
    const address = await window["ethereum"].enable();
    const returned_address = address.length ? address[0] : null;
    if (returned_address) {
      const netID = await web3.eth.net.getId();
      // in prod ==1 valid
      if (netID != 1 && prod) {
        throw new Error("Please connect to Ethereum Mainnet");
      } else {
        return returned_address;
      }
    } else {
      throw new Error("No wallet is connected");
    }
  } else {
    throw new Error("Please install Metamask");
  }
}

export async function GetMintPrice() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.mintPrice().call().then(price => price);
}

export async function GetMintStartTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.publicSaleStartTime().call().then(time => time);
}

export async function GetRemainingMintCount() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.remainingCount().call().then(amount => amount);
}

export async function BuyButtonPress(address, buyCount, price) {
  if (!contractAddress) {
    throw new Error(
      "Please come back when public mint starts. Follow us on Twitter."
    );
  } else if (!address) {
    throw new Error("Please connect wallet first!");
  } else {
    let contract = new web3.eth.Contract(ABI, contractAddress);
    var itemPrice = new bigNumber(price);
    let sumAmount = itemPrice.multipliedBy(buyCount);
    return contract.methods
      .mint(buyCount)
      .estimateGas({ from: address, value: sumAmount })
      .then(function(gasAmount) {
        let gasLimit = new bigNumber(gasAmount)
          .multipliedBy(110)
          .dividedBy(100)
          .toFixed(0);
        return contract.methods
          .mint(buyCount)
          .send({ value: sumAmount, from: address, gasLimit: gasLimit })
          .then(res => {
            return res.transactionHash;
          });
      })
      .catch(err => {
        console.log('buy err', err.message)
        let msg = err.message
        if (msg.includes("this address has already purchased")) {
          msg = "This address has already purchased";
        } else if (msg.includes("insufficient funds")) {
          msg = "Insufficient wallet balance";
        } else if (msg.includes("Purchased already")) {
          msg = "Failed to purchase again";
        } else if (msg.includes("failed to verify first WL merkle root")) {
          msg = "Failed to verify in OG whitelist";
        } else if (msg.includes("failed to verify second WL merkle root")) {
          msg = "Failed to verify in whitelist";
        } else if (msg.includes("no active sale right now")) {
          msg = "mint not yet started";
        } else {
          msg = "mint failed";
        }
        throw new Error(msg);
      });
  }
}

// export function GetProof(address, number, stage) {
//   switch (stage) {
//     case 0:
//       return [];
//     case 1:
//       return Proof(address, number, firstWhiteList)
//     case 2:
//       return Proof(address, number, secondWhiteList)
//     case 3:
//       return []
//   }
// }