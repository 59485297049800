<template>
  <section>
    <h1 class="text-center">Roadmap 2.0</h1>
    <ul class="mt-4">
        <li>
          <h3>Increase team strength and build community consensus </h3>
          <p>We continue to recruit talent from all walks of life, especially to organize engineers to set up sniper teams, analyze blue chips or key projects, and recruit relevant personnel from catering backgrounds., Inviting big guys or project parties to share their experiences from time to time. Ultimately, we want to use the spirit of the DAO and let Holder decide the direction of development together.</p>
        </li>
        <li>
          <h3>Operate exclusive brands and launch peripheral products </h3>
          <p>Brand is a spiritual symbol and a value concept. Casual DAO is not just a tea shop, It is also a free and casual attitude, but also respectful of others' attitude towards life. We will make it easy for all DAO friends to learn knowledge and have the ability to boldly guess the future. Adhering to this spiritual concept, operating a brand that belongs to casual DAO, Peripheral products have been launched one after another.</p>
          <p>When S.B.D rings in your ears, You'll know it's a quality assurance. A bright light that leads us into the blockchain, An imaginary space without boundaries, Diversity and common prosperity are our common aspirations.</p>
        </li>
        <li>
          <h3>Virtual real integration of tea shops, holder airdrops gold </h3>
          <p>We will open the first tea shop in Taiwan that combines blockchain, and in the future you can use token payments. The DAO friends who hold our NFT will have an exclusive secret community, get the most instant project information, receive a variety of Haokang critical hits, and enjoy the rights and interests of airdrop tokens in the future. The harvest is far beyond your imagination.</p>
        </li>
        <li>
          <h3>Hold the 2D and take the 3D and travel through the future metaverse </h3>
          <p>We will build an NFT tea concept hall in the metaverse, and the DAO will decide the direction of the purchase of the metaverse land, as well as any possible metascopic applications.</p>
          <p>Every holder who holds the 2D NFT will get an airdropped 3D drink character!</p>
          <p>You'll be transformed into cool drinkers in the metaverse, drinking with us,</p>
          <p>chatting about projects, and playing games. To achieve our shared dream, it all starts with mutual aid.</p>
        </li>
        <li>
          <h2>VISION</h2>
          <p>Create an immortal teahouse for all DAO friends, and strive to push the blockchain industry to the top career.</p>
        </li>
        <li>
          <h4>ILLUSTRATE</h4>
          <p>We hope to integrate the catering industry into the virtual and real integration, to create a teahouse that will never perish, and we are eager for more people from all walks of life who have a strong will for the blockchain industry to create their own sustainable business through the services we provide.</p>
        </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Roadmap",
};
</script>

<style scoped>
li {
    list-style: none;
    text-align: start;
}
li h3 {
  color: #0EF5E8;
}
li h2 {
  color: #FFF500;
}
li h4 {

}
</style>
