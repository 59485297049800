<template>
  <section>
    <h1 class="text-center">About us</h1>
    <p class="text-start px-4 mt-4">
      We are a group of catering people who have been deeply involved in the
      catering industry for many years, but invariably become good comrades who
      are keen on blockchain.
    </p>
    <p class="text-start px-4 mt-4">
      Gathering for dinner one day, in casual chat, I accidentally made a big
      dream of a Metaverse Tea Shop.
    </p>
    <p class="text-start px-4 mt-4">
      So we set up an official website and community, and found experts in
      various fields who support our ideas, and formed a "casual DAO".
    </p>
    <p class="text-start px-4 mt-4">
      Think about the situation of buying drinks in daily life. When someone
      asks us: "Hey, what do you want to drink?" The first reaction seems to be:
      "I don't know, just do whatever." This became one of the inspirations for
      our name.
    </p>
    <p class="text-start px-4 mt-4">
      With the arrival of the NFT metaverse boom, we believe that a
      decentralized autonomous organization (DAO) will be the best way to
      operate, and we will gradually move towards the DAO module, allowing every
      NFT holder to collaborate and make decisions together.
    </p>
    <p class="text-start px-4 mt-4">
      In the future, we hope to connect like-minded catering people to jointly
      build a virtual or physical NFT tea shop, and open our own METAVERSE tea
      concept hall. We will help young entrepreneurs who want to develop
      projects and create a path to the NFT field. Here, you can exchange NFT
      information with many colleagues, and everything starts from mutual
      assistance.
    </p>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped></style>
