<template>
  <section>
    <h1 class="text-center">Recommender</h1>
    <ul class="text-center mt-4">
      <li>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 0 1200 1200"
        >
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/background/8.png"
          />
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/body/0.png"
          />
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/cloth/9.png"
          />
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/hand/fist/0.png"
          />
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/weapon/3.png"
          />
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/head/0.png"
          />
          <image
            href="https://neotokyo.mypinata.cloud/ipfs/QmPLW6u5MRut1b8iyVc47ET5zAj9VaG2GwyjcuKLoetWsT/helm/35.png"
          />
        </svg>
        <h3>Neotokyo#1979</h3>
      </li>
      <li>
        <img
          class="Image--image"
          src="https://lh3.googleusercontent.com/Qgw54YKcrWQPwCIY0pzog_9SYLBSJ8GAqrw3aHMdMMpPP-rfNLX2AXxHGmIDXucc5A4ERGpdpCx3GxyHSoXBRfdwr6yqRmL4NOaYSQ=w600"
          style="
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          "
        />
        <h3>Bored Ape Yacht Club#6809</h3>
      </li>
      <li>
        <img
          class="Image--image"
          src="https://lh3.googleusercontent.com/QManf3H61wPJAKIR2mkHMGfL6J8ITguHwDsdBvkf_R6cMxTMko3myfjCCXP24pb8q8fw4A5W-4RdolqD5lgmcSiLu13jj_-lCyck=w600"
          style="width: auto; height: auto; max-width: 100%; max-height: 100%"
        />
        <h3>Fomodog#491</h3>
      </li>
      <li>
        <img
          class="Image--image"
          src="https://lh3.googleusercontent.com/xezH6Ssvj9I97XHCPofwy-2_iyC9F87k53AyAJLOPEFnklq0hnMVkQQWAsBHCU96VwHRLKtBr52J9Whr1cX6Dhk9eNNa5d4lYCLr=w600"
          style="width: auto; height: auto; max-width: 100%; max-height: 100%"
        />
        <h3>Fomodog#359</h3>
      </li>
      <li>
        <img
          class="Image--image"
          src="https://lh3.googleusercontent.com/JQ8MIoJbZKhce7v1lyvcS94l4egeLTjS-s-n9GM4XrTzb8ccTu1DBG2VwVPkg2Hjcff4vMDfGw2GOguekAvoioRSFsAhJNygqDEXybw=w600"
          style="
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          "
        />
        <h3>ALPACADABRAZ#8557</h3>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style scoped>
li {
  list-style: none;
  text-align: start;
  display: inline-block;
  margin: 0 20px;
  width: 25%;
  height: 25%;
  max-width: 300px;
  text-align: center;
  vertical-align: top;
}

li img,
li svg {
  border-radius: 20px;
}

svg:root {
  width: 100%;
  height: 100%;
}

h3 {
  margin-top: 20px;
}

@media (max-width: 992px) {
  li {
    display: block;
    width: 70%;
    height: 70%;
    margin: 0 auto;
  }
}
</style>
