<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top p-4">
    <div class="container-fluid p-0">
      <a class="navbar-brand" href="#">Sueibian DAO</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li>
              <a class="nav-link nav-icon" href="https://twitter.com/sueibiandao" target="_blank"><img src="../assets/twitter.png" width="32" height="32"></a>
          </li>
          <li>
              <a class="nav-link nav-icon" href="https://discord.gg/6YvUJMs5" target="_blank"><img src="../assets/discord.png" width="32" height="32"></a>
          </li>
          <!-- <li>
              <a class="nav-link nav-icon" href="#" target="_blank"><img src="../assets/telegram.png" width="32" height="32"></a>
          </li> -->
          <li>
              <a class="nav-link nav-icon" href="https://www.instagram.com/sueibiandao/" target="_blank"><img src="../assets/instagram.png" width="32" height="32"></a>
          </li>
          <!-- <li>
              <a class="nav-link nav-icon" href="#" target="_blank"><img src="../assets/opensea.png" width="32" height="32"></a>
          </li> -->
          <li class="d-md-flex align-items-center ms-md-3 mt-sm-3 mt-md-0">
            <button type="button" class="btn bg-light" @click="connect">
              {{ maskAddress ? maskAddress : "Connect Wallet" }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ConnectWallet } from "../services/wallet";
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "Header",
  setup() {
    const store = useStore()
    let address = computed(function () {
      return store.state.address
    })
    function setAddress(address) {
      store.commit("setAddress", address)
    }
    return {
      address,
      setAddress
    }
  },
  data: () => {
    return {
      maskAddress: null
    };
  },
  methods: {
    connect: function () {
      ConnectWallet()
        .then((data) => {
          this.setAddress(data)
          this.maskAddress = data.replace(
            data.substring(4, data.length - 4),
            "..."
          );
        })
        .catch((err) => {
          this.$swal(err.message, "", "error");
        });
    },
  },
};
</script>

<style scoped>
.navbar-brand {
  font-size: 2rem;
}
.nav-icon img {
  width: 60px;
  height: 60px;
}
.bg-dark {
  background-color: black !important;
}
</style>
