<template>
  <Carousel :items-to-show="4" :wrap-around="true" :autoplay="2000">
    <Slide v-for="(slide, index) in slides" :key="index">
      <div class="carousel__item mx-2">
        <img :src="getImgUrl(slide)" loading="eager" alt="" class="d-block w-100" />
      </div>
    </Slide>

    <template #addons> </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Preview",
  components: {
    Carousel,
    Slide,
  },
  data: () => {
    return {
      slides: [1,2,3,1,2,3,1,2,3],
    };
  },
  methods: {
    getImgUrl: function (name) {
      var images = require.context('../assets/', false, /\.png$/)
      return images('./' + name + ".png")
    }
  }
});
</script>

<style scoped>
.carousel {
  transform: rotate(2deg);
  width: 99%;
}
.carousel img {
  border-radius: 20px;
}
@media (max-width: 992px) {
  .carousel {
    transform: none;
  }
}
</style>
