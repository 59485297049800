<template>
  <section>
    <h1 class="text-center">Public Offering</h1>
    <!-- <h3>There are 9700 MINT sheets remaining. Please follow the SBD Discord and wait for the announcement.</h3> -->
    <ul class="mt-4">
        <li class="text-center">3/15 21:00 - public mint : 4700</li>
    </ul>
    <h3 class="mt-5">Price : {{ price / 1000000000000000000 }}</h3>
    <!-- <h3 class="mt-5">Price : 0.2</h3> -->
    <h3 class="mt-5">Remaining: {{ amount }}</h3>
    <button  type="button" class="btn bg-light mt-5 mint" @click="buy">MINT</button>
  </section>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import { GetMintPrice, GetRemainingMintCount, GetMintStartTime, BuyButtonPress } from "../services/wallet"

export default {
  name: "Mint",
  setup() {
    const store = useStore()
    let address = computed(function () {
      return store.state.address
    })
    return {
      address
    }
  },
  created: function () {
    GetMintPrice().then(price => {
      this.price = price
      console.log('price', price)
    }).catch(err => console.log(err))
  },
  mounted: function () {
    this.getStartTime()
    setInterval(this.monitor, 1000) && this.monitor()
  },
  data: function() {
      return {
        price: 0,
        amount: 0,
        start: 0,
      }
  },
  methods: {
    getAmount: function () {
      GetRemainingMintCount(parseInt(this.stage)).then(amount => {
        console.log('amount', amount);
        this.amount = amount;
      }).catch(err => console.log(err));
    },
    getStartTime: function () {
      GetMintStartTime().then(time => {
        this.start = time + '000'
        console.log('start', time)
      }).catch(err => console.log(err))
    },
    monitor: function () {
      this.getAmount()
    },
    buy: async function () {
      console.log(this.price)
      console.log(this.address)
      let t = new Date('2022/3/15 21:00:00 GMT+08:00').getTime() - new Date().getTime();
      if (t > 0) {
        this.$swal({
          title: 'Public Offering will start at 9 PM, Mar 15, 2021',
          confirmButtonText: 'OK'
        })
        return
      }
      if (!this.address || !this.price) {
        this.$swal({
          title: 'Please connect Metamask',
          confirmButtonText: 'OK'
        })
        return
      }
      const { value: buyAmount } = await this.$swal({
        title: 'How many do you want to buy?(max 5)',
        input: 'number',
        inputLabel: 'amount',
        inputAttributes: {
          min: 1,
          max: 5,
          step: 1
        },
        inputValue: 1,
        confirmButtonText: 'Buy'
      });
      BuyButtonPress(this.address, buyAmount, this.price)
        .then(data => {
          console.log(data)
        })
        .catch(err => {
          this.$swal(err.message, '', 'error')
        })
    }
  }
};
</script>

<style scoped>
li {
    list-style: none;
    text-align: start;
}

.mint {
    font-size: 2.5rem;
}
</style>
