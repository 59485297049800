<template>
  <Header />
  <Mint />
  <Preview />
  <About />
  <Roadmap />
  <Founder />
  <Recommender />
  <Disclaimer />
  <Footer />
</template>

<script>
import Header from "./components/Header.vue"
import Preview from "./components/Preview.vue"
import Mint from "./components/Mint.vue"
import About from "./components/About.vue"
import Roadmap from "./components/Roadmap.vue"
import Founder from "./components/Founder.vue"
import Recommender from "./components/Recommender.vue"
import Disclaimer from "./components/Disclaimer.vue"
import Footer from "./components/Footer.vue"

export default {
  name: 'App',
  components: {
    Header,
    Preview,
    Mint,
    About,
    Roadmap,
    Founder,
    Recommender,
    Disclaimer,
    Footer
  }
}
</script>

<style>
@font-face {
  font-family: "Test";
  src: local("Test"), url("./assets/fonts/jf-openhuninn-1.1.ttf");
}
body {
  font-size: 1.5rem;
  background-color: black;
  color: white;
}
#app {
  font-family: "Test", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  padding-top: 60px;
}
section {
  padding: 50px 100px;
}
h1 {
  font-size: 3rem;
  font-weight: 800;
  letter-spacing: 0.25rem;
  margin-bottom: 40px;
}
ul {
  padding: 0;
  margin: 0;
}

@media (max-width: 992px) {
  section {
    padding: 30px 30px;
  }
  h1 {
    font-size: 2.5rem;
  }
}
</style>
