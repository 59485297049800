<template>
  <footer class="text-center text-white">
    <!-- Copyright -->
    <div class="text-center p-4">
      © 2021 Copyright:
      <a class="text-reset fw-bold" href="https://sueibiandao.io/"
        >sueibiandao.io</a
      >
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
    padding: 50px 0;
    background-color: black;
}
</style>
