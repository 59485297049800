<template>
  <section>
    <h1 class="text-center">whatever SUEI BIAN.DAO<br />Most Responsible Disclaimer</h1>
    <p class="text-center">VVery contradictory, right? Responsibility is that we are always there because DAO is with you like Jesus, but ❗️❗️❗️We have a disclaimer</p>
    <p class="text-center">What is the purpose of blockchain ❓ Decentralization means that there is no national government, consortium, organization, or organization that can easily lead the development of the free virtual currency market and digital asset market structure, so there are several things that must be detailed to everyone first. instruction</p>
    <p class="text-center">Every buyer needs to be aware of blockchain commodities or cryptocurrency commodities. The market price fluctuates wildly, which is uncontrollable and has a high degree of risk. Careful consideration and evaluation must be made before investing.</p>
    <h3 class="mt">The purchaser's profit or loss caused by the transaction of this commodity</h3>
    <h3>Without any legal or financial responsibility, and with the following statement:</h3>
    <ul class="mt-4">
        <li class="text-start">The purchase of this product does not mean that it has exclusive or exclusive property rights to the works linked to and related to this product, nor does it represent the authorization of any intellectual property rights in the product, and the purchaser agrees not to use the product for commercial purposes.</li>
        <li class="text-start">This product is not a financial product or any investment wealth management product. The design purpose of the product is not for investment purposes. Whether purchasing or selling this product, it is the purchaser's own decision and responsibility. The company cannot guarantee the future changes of the product. And it cannot be predicted or controlled. If there are any price fluctuations, it has nothing to do with our company.</li>
        <li class="text-start">Although the works linked to this product have obtained the consent and authorization of the material provider at the time of production, the company does not guarantee the possession, sale or use of this product. The intellectual property rights of any third party will not constitute any infringement or infringement disputes. If the purchaser needs to purchase this product, the company will not be responsible for the third party to claim legal rights such as intellectual property rights against the purchaser.</li>
        <li class="text-start">Buyers of this product should pay attention to the laws and public order and good customs of the country or region where they are located. For this product or the works linked to this product, whether there is any violation of the laws or public order and good customs of the country or region where the product is located, the buyer must pay attention to it before purchasing. And understand the relevant risks, if there are any legal problems or illegal risks, the buyer is responsible for it.</li>
        <li class="text-start">Become the largest gathering place for NFT players to exchange information.</li>
    </ul>
    <p class="text-center mt-5">Please read the statement carefully before purchasing, the purchase means that you agree to confirm all the risks of this statement and are willing to bear</p>
  </section>
</template>

<script>
export default {
  name: "Disclaimer",
};
</script>

<style scoped>
li {
    list-style: none;
    text-align: start;
    counter-increment: li;
}

li::before {
  content: counter(li)".";
  display: inline-block;
  width: 1em;
  margin-left: -1.5em;
  margin-right: 0.5em;
}

h1 {
  color: #FFFF6D;
}

.mt {
  margin-top: 100px;
}

@media (max-width: 992px) {
  ul {
    padding: 0;
  }

  li {
    display: block;
    width: 70%;
    height: 70%;
    margin: 15px auto;
  }
}
</style>
