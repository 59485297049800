<template>
  <section>
    <h1 class="text-center">Founder</h1>
    <ul class="text-center mt-4">
        <li>
            <div>
                <img src="../assets/blind-box-1.png" height="100" />
                <h3>Eson | SBD</h3>
            </div>
        </li>
        <li>
            <div>
                <img src="../assets/blind-box-2.jpg" height="100" />
                <h3>Ian | SBD</h3>
            </div>
        </li>
        <li>
            <div>
                <img src="../assets/blind-box-3.png" height="100" />
                <h3>MM | SBD</h3>
            </div>
        </li>
        <li>
            <div>
                <img src="../assets/blind-box-4.png" height="100" />
                <h3>Bruce | SBD </h3>
            </div>
        </li>
        <li>
            <div>
                <img src="../assets/blind-box-5.png" height="100" />
                <h3>Nora | SBD</h3>
            </div>
        </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style scoped>
ul {
    text-align: start;
}
li {
  list-style: none;
  text-align: start;
  display: inline-block;
  margin: 0 20px;
  width: 25%;
  height: 25%;
  max-width: 300px;
  text-align: center;
}
li img {
  width: 100%;
  height: 100%;
}
h3 {
  margin-top: 20px;
}
@media (max-width: 992px) {
  li {
    display: block;
    width: 70%;
    height: 70%;
    margin: 0 auto;
  }
}
</style>